















































































































































import api from "@/api";
import { Component, Prop, Vue } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import AbSelect from "@/components/AbSelect.vue";
import {
  SupplierDto,
  SupplierDtoPagedResultDto,
  SupplierProductSaleMainDto,
} from "@/api/appService";
import Settlement from "@/views/purchase/commoditySalesRecord/settlement.vue";

@Component({
  name: "CommoditySalesRecord",
  components: {
    PagedTableView,
    AbSelect,
    Settlement,
  },
})
export default class CommoditySalesRecord extends Vue {
  @Prop({ required: true, default: "" })
  from!: string;

  queryForm = {
    supplierId: undefined,
    salesTime: undefined,
    isSettled: undefined,
  };

  productSaleMainId = 0;
  supplierList: SupplierDto[] = [];
  settledStatusList = [
    {
      text: (this as any).$l.getLanguageText(
        "commoditySalesRecord.toBeSettled"
      ),
      value: false,
    },
    {
      text: (this as any).$l.getLanguageText("commoditySalesRecord.settled"),
      value: true,
    },
  ];

  get fromFinance() {
    return this.from === "Finance";
  }

  async created() {
    debugger;
    this.from = this.$route.params.from;
    if (this.fromFinance) {
      await this.fetchSupplierList();
    }
  }

  async fetchSupplierList() {
    await api.supplier
      .getAll({ maxResultCount: 65535 })
      .then((res: SupplierDtoPagedResultDto) => {
        this.supplierList = res!.items!;
      });
  }

  fetchData(params: any) {
    return api.supplierProductSalesMain.getAll(params);
  }

  handleSettlement(row: SupplierProductSaleMainDto) {
    this.productSaleMainId = row.id!;
    (this.$refs.settlementForm as any).show = true;
  }

  handleDetails(row: SupplierProductSaleMainDto) {
    this.$router.push({
      name: "commoditySalesRecordDetails",
      params: {
        productSaleMainId: row.id!.toString(),
      },
    });
  }
}
