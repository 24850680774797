






































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import {
  SupplierProductSaleMainDto,
  SupplierProductSaleSettlementPostDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import api from "@/api";

@Component({
  name: "Settlement",
})
export default class Settlement extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  loading = true;
  show = false;

  form: SupplierProductSaleSettlementPostDto = {};
  defaultData: SupplierProductSaleSettlementPostDto = {
    id: 0,
  };

  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      if (this.dataId) {
        api.supplierProductSalesMain.settled({ body: this.form }).then(() => {
          this.show = false;
          this.$message.success(
            (this as any).$l.getLanguageText("commoditySalesRecord.tips.success").toString()
          );
        });
      }
    });
  }

  @Watch("show")
  onShowChange(value: boolean) {
    if (value) {
      if (this.dataId) {
        this.loading = true;
        api.supplierProductSalesMain
          .get({ id: this.dataId })
          .then((res: SupplierProductSaleMainDto) => {
            if (res) {
              this.form = {
                actualSettlementAmount: res.actualSettlementAmount,
                id: res.id,
              };
            } else {
              this.form = { ...this.defaultData };
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.form = { ...this.defaultData };
        this.loading = false;
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  linkRule = {
    actualSettlementAmount: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("commoditySalesRecord.tips.actualSettlementAmount"),
        trigger: "blur",
      },
    ],
  };

  cancel() {
    this.show = false;
  }
}
